

















import { Component, Vue, Prop, Watch } from "vue-property-decorator";
@Component({})
export default class Name extends Vue {
  @Prop()
  private options: any;
  @Prop()
  private placeholder: any;
  @Prop()
  private val: any;
  private value: any = "";
  @Watch("val", { immediate: true })
  private valChange() {
    this.value = this.val;
  }
  private change(e: any) {
    this.$emit("change", e);
  }
}
