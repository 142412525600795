


























import { Component, Vue, Prop, Watch } from "vue-property-decorator";
@Component({})
export default class Name extends Vue {
  @Prop()
  private options: any;
  @Prop()
  private placeholder: any;
  @Prop({ type: String, default: "220" })
  private width: any;
  @Prop({ type: String, default: "姓名" })
  private name: any;
  @Prop({ type: String, default: "user_id" })
  private keyVal: any;
  @Prop()
  private val: any;
  private value: any = [];
  @Watch("val", { immediate: true })
  private valChange() {
    this.value = this.val;
  }
  private change(e: any) {
    this.$emit("change", e);
  }
  private remoteMethod(e: any) {
    this.$emit("remoteMethod", e);
  }
}
